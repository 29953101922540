export const brands = [
	'6 mm Proshop',
	'A&K',
	'AGM',
	'ARES',
	'ARMORER WORKS',
	'Army Armament',
	'APS',
	'ASG',
	'ATS',
	'BG Tactical',
	'BO Manufacture',
	'Bolt',
	'Both Elephant',
	'C-TAC',
	'Classic Army',
	'Crosman',
	'Cybergun',
	'CYMA',
	'D-Boys',
	'DeepFire',
	'Double Eagle',
	'DYTAC',
	'E&L',
	'Echo 1',
	'EMG',
	'Galaxy',
	'G&G Armament',
	'G&P',
	'Galaxy',
	'GHK',
	'Ho Feng Corp',
	'ICS',
	'Jag Arms',
	'Jing Gong',
	'Jin Peng',
	'King Arms',
	'KJ Works',
	'Krytac',
	'KWA',
	'KSC',
	'KWC',
	'Lancer Tactical',
	'LCT',
	'Lonex',
	'Lucid',
	'Mad Bull Airsoft',
	'Magpul PTS',
	'Marushin',
	'Maruzen',
	'Matrix',
	'Modify',
	'Pro Arms',
	'RWA',
	'Silesia Factory',
	'Specna Arms',
	'SRC',
	'Systema Engineering',
	'T.I.E.R.',
	'Tanaka Works',
	'Tokyo Marui',
	'Umarex (Elite Force)',
	'VFC',
	'WE Tech',
	'Wei-E Tech',
	'Well',
	'Western Arms',
	'WinGun',
] as const

export type Brand = typeof brands[number]
